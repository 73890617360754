import { useState } from "react";
import { useEffect } from "react"
import './Header.scss'
import { Link, useLocation } from "react-router-dom";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";

export default function Header() {
	const location = useLocation();
	const [path, setPath] = useState('')
	const WebApp = useWebApp();

	useEffect(() => {
		setPath(location.pathname)
	}, [location])

	useEffect(() => {
		if (path.includes('discount')) {
			WebApp.setHeaderColor(WebApp.themeParams.bg_color) //'bg_color'
		} else {
			WebApp.setHeaderColor(WebApp.themeParams.secondary_bg_color)
		}
	}, [WebApp, path])

	return (
		<>
		{path.includes('discount') ?
			<a className='boxPinned' href={process.env.REACT_APP_LINK_TO_CHANNEL}>
				<b>Канал с обновлением промокодов</b>
				<span>{process.env.REACT_APP_LINK_TO_CHANNEL}</span>
			</a>
		: null}
			<header className={'Header' + (path.includes('categories') ? ' Header--active' : '')}>
				<Link to='/projects' className={'Header-link ' + (path.includes('projects') ? 'Header-link--active' : '')}>Проекты</Link>
				<Link to='/categories' className={'Header-link ' + (path.includes('categories') ? 'Header-link--active' : '')}>Категории</Link>
			</header>
		</>
	)
}
